@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --cimon: #f76566;
    --primary: #022648;
    --secondary: #eac435;
    --secondaryhover: #b99513;
    --success: #3ac47d;
    --successhover: #23764b;
    --info: #11698E;
    --infohover: #0071b3;
    --warning: #f7b924;
    --warninghover: #b38416;
    --danger: #d92550;
    --dangerhover: #991a3a;
    --light: #999999;
    --lighthover: #343a40;
    --dark: #343a40;
    --focus: #444054;
    --cardtitle: rgba(13, 27, 62, 0.7);
    --cardheader: #008eb3;
    --cardheaderhover: #007a99;
    --blue: #0000b3;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
    font-family: 'Poppins', sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5;
    color: #022648;
    text-align: left;
    background: #f2f2f2;
    overflow-x: hidden;
}
body::-webkit-scrollbar {
    width: 5px;
}
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
    background: #022648;
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
    background: #eac435;
}
.shadow_bottom {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}
.shadow_bottom:hover {
    box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
}
.overflowY_scroll {
    overflow-y: scroll;
}

.sub_scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.sub_scrollbar::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
}
.sub_scrollbar::-webkit-scrollbar-thumb {
    background: #4d4d4d;
    border-radius: 10px;
}
.sub_scrollbar::-webkit-scrollbar-thumb:hover {
    background: #999999;
}
a {
    color: #022648;
    text-decoration: none;
    background-color: transparent;
}

.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1px;
    overflow: hidden;
    background: #000;
    opacity: .1;
}
.verical_divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 30px;
    overflow: hidden;
    background: #000;
    width: 1px;
    opacity: .1;
}
/* .visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}
.cursor_pointer {
    cursor: pointer !important;
}
.cursor_notallowed {
    cursor: not-allowed !important;
}
.cursor_default {
    cursor: alias !important;
}
.text_decoration_underline {
    text-decoration: underline;
}
.direction_ltr {
    direction: ltr;
}

.text_overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text_lowercase {
    text-transform: lowercase !important;
}
.text_uppercase {
    text-transform: uppercase !important;
}
.text_capitalize {
    text-transform: capitalize !important;
}
.text_transform_none {
    text-transform: none !important;
}
.font_weight_light {
    font-weight: 300 !important;
}
.font_weight_normal {
    font-weight: 400 !important;
}
.font_weight_500 {
    font-weight: 500 !important;
}
.font_weight_600 {
    font-weight: 600 !important;
}
.font_10 {
    font-size: 10px !important;
}
.font_11 {
    font-size: 11px !important;
}
.font_12 {
    font-size: 12px !important;
}
.font_13 {
    font-size: 13px !important;
}
.font_14 {
    font-size: 14px !important;
}
.font_15 {
    font-size: 15px !important;
}
.font_16 {
    font-size: 16px !important;
}
.font_17 {
    font-size: 17px !important;
}
.font_18 {
    font-size: 18px !important;
}
.font_19 {
    font-size: 19px !important;
}
.font_20 {
    font-size: 20px !important;
}
.text_blue {
    color: var(--blue) !important;
}
.text_info {
    color: var(--info) !important;
    transition: 0.3s;
}
.text_infohover:hover {
    color: var(--infohover) !important;
    cursor: pointer;
}
.text_white {
    color: #fff !important;
}
.text_cardtitle {
    color: var(--cardtitle) !important;
}
.text_primary {
    color: var(--primary) !important;
    transition: 0.3s;
}
.text_primaryhover:hover {
    color: var(--secondary) !important;
    cursor: pointer;
}
.text_secondary {
    color: var(--secondary) !important;
}
.text_danger {
    color: var(--danger) !important;
    transition: 0.3s;
}
.text_dangerhover:hover {
    color: var(--dangerhover) !important;
    cursor: pointer;
}
.text_success {
    color: var(--success) !important;
}
.text_light {
    color: var(--light) !important;
    transition: 0.3s;
}
.text_lighthover:hover {
    color: var(--lighthover) !important;
    cursor: pointer;
}
.text_hover_secondary {
    transition: 0.3s;
}
.text_hover_secondary:hover {
    color: #eac435 !important;
    cursor: pointer !important;
}
.text_focus {
    color: var(--focus);
}
.text_muted {
    color: #9eabc0 !important;
}
.text_grey {
    color: grey !important;
}
.bg_blue {
    background-color: var(--blue) !important;
}
.bg_transparent {
    background-color: transparent !important;
}
.bg_white {
    background-color: white;
}
.bg_primary {
    background-color: var(--primary) !important;
}
.bg_secondary {
    background-color: var(--secondary) !important;
}
.bg_focus {
    background-color: var(--focus);
}
.bg_info {
    background-color: var(--info) !important;
}
.bg_success {
    background-color: var(--success) !important;
}
.bg_green {
    background-color: var(--green) !important;
}
.bg_cimon {
    background: var(--cimon) !important;
}
.bg_danger {
    background-color: var(--danger) !important;
}
.bg_light {
    background-color: #d9d9d9;
}
.bg_cardheader {
    background-color: var(--cardheader);
}
*/
/* badges */
.badge {
    display: flex;
    padding: 0.3em;
    font-size: 90%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    /* vertical-align: baseline; */
    border-radius: 50%;
    margin-left: -15%;
    align-items: center;
    justify-content: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}
.badge:empty {
    display: none;
}
.badge_secondary {
    padding: 0.4em 0.3rem;
    min-width: 2.5vh;
    height: 2.5vh;
    border-radius: 5px;
    background: var(--secondary);
    color: white;
    font-size: 12px !important;
    font-weight: 400 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* line-height: 3vh; */
}
/* Table */
table {
    border-collapse: collapse;
}
th {
    text-align: inherit;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0);
}
.table th,
.table td {
    padding: 0.55rem;
    border-top: 1px solid #e9ecef;
}
.table th.mw-200,
.table td.mw-200 {
    max-width: 200px;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef;
    font-size: 14px;
    color:#000
}
.table td {
    color: var(--focus);
    font-size: 14px;
}
.table_bordered {
    border: 1px solid #e9ecef;
}
.table_bordered th,
.table_bordered td {
    border: 1px solid #e9ecef;
}
.table_bordered thead th,
.table_bordered thead td {
    border-bottom-width: 2px;
}
.table_borderless th,
.table_borderless td,
.table_borderless thead th,
.table_borderless tbody + tbody {
    border: 0;
}
.tablewithbg tr{
    background-color: rgba(217, 217, 217, 0.2);
    border-radius:20px !important;
}
.tablewithbg td{
    padding: 25px 0;
}
.table_striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}
.table_hover tbody tr:hover {
    background-color: #e0f3ff;
}

.table_responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table_responsive > .table_bordered {
    border: 0;
}

.table_responsive::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.table_responsive::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
}
.table_responsive::-webkit-scrollbar-thumb {
    background: #4d4d4d;
    border-radius: 10px;
}
.table_responsive::-webkit-scrollbar-thumb:hover {
    background: #999999;
}
.table_translation {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 5px;
    font-weight: 500;
    width: 100px;
}
.table_translation i {
    font-size: 20px;
}
.table_icon {
    font-size: 20px;
    transition: 0.3s;
    cursor: pointer;
}
.table_icon:hover {
    color: var(--secondary);
}
.image_table {
    width: 6vh;
    height: 6vh;
    /* box-shadow: 0 0 10px #ccc; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
}
.image_table img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}
.shownumber_cont {
    min-width: 3rem;
    background: white;
    box-shadow: 0 0 10px #ccc;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
}
.card_input_table {
    min-width: 90%;
    height: 35px;
}
.card_input_table imput {
    height: 35px;
}

/* Buttons */
.btn_circle {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    line-height: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 1.5rem;
    font-size: 0.75rem;
    text-transform: capitalize;
    border-radius: 10px;
    transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}
.btn_icon_resp {
    padding: 0;
    width: 3vh;
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
}
@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    text-decoration: none;
}
.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none;
}
.btn.disabled,
.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
a.btn.disabled {
    pointer-events: none;
    cursor: not-allowed;
}
.btn_tranparent {
    color: #fff;
    background-color: tranparent;
    border-color: tranparent;
}
.btn_black {
    color: #fff;
    background-color: #000;
    border-color: #000;
    transition: .3s;
}
.btn_black:hover {
    color: #fff;
    background-color: rgb(0, 0, 0);
    border-color: #000;
    opacity: 0.5 !important;
}
.btn_black.disabled,
.btn_black:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000;
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_warning {
    color: #fff;
    background-color: var(--warning);
    border-color: var(--warning);
}
.btn_warning:hover {
    color: #fff;
    background-color: var(--warninghover);
    border-color: var(--warninghover);
}
.btn_warning.disabled,
.btn_warning:disabled {
    color: #fff;
    background-color: var(--warning);
    border-color: var(--warning);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn_primary:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_primary.disabled,
.btn_primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_secondary {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_secondary:hover {
    color: #fff;
    background-color: var(--secondaryhover);
    border-color: var(--secondaryhover);
}
.btn_secondary.disabled,
.btn_secondary:disabled {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_success {
    color: #fff;
    background-color: var(--success);
    border-color: var(--success);
}
.btn_success:hover {
    color: #fff;
    background-color: var(--successhover);
    border-color: var(--successhover);
}
.btn_success.disabled,
.btn_success:disabled {
    color: #fff;
    background-color: var(--success);
    border-color: var(--success);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_info {
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);
}
.btn_info:hover {
    color: #fff;
    background-color: var(--infohover);
    border-color: var(--infohover);
}
.btn_info.disabled,
.btn_info:disabled {
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_danger {
    color: #fff;
    background-color: var(--danger);
    border-color: var(--danger);
}
.btn_danger:hover {
    color: #fff;
    background-color: var(--dangerhover);
    border-color: var(--dangerhover);
}
.btn_danger.disabled,
.btn_danger:disabled {
    color: #fff;
    background-color: var(--danger);
    border-color: var(--danger);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc;
}
.btn_grey {
    color: #fff;
    background-color: var(--focus);
    border-color: var(--focus);
}
.btn_grey:hover {
    color: #fff;
    background-color: var(--dark);
    border-color: var(--dark);
}
.btn_cardheader {
    color: #fff;
    background-color: #000;
    border-color: #000;
    transition: .3s;
}
.btn_cardheader:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    opacity: 0.7 !important;
}
.btn_cardheader.disabled,
.btn_cardheader:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000;
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_cardtitle {
    color: #fff;
    background-color: var(--cardtitle);
    border-color: none;
}
.btn_cardtitle:hover {
    color: #fff;
    background-color: rgba(13, 27, 62, 0.8);
    border-color: rgba(13, 27, 62, 0.7);
}
.btn_cardtitle.disabled,
.btn_cardtitle:disabled {
    color: #fff;
    background-color: var(--cardtitle);
    border-color: var(--cardtitle);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_card_icon {
    width: 3vh;
    height: 3vh;
    border-radius: 7px;
}
.btn_card_icon i {
    font-size: 15px;
}
.btn_circle {
    border-radius: 50% !important;
}
.subpage_backicon {
    width: 2.8vh;
    height: 2.8vh;
    border-radius: 50%;
    /* background-color: rgba(13, 27, 62, 0.7); */
    background-color: var(--cardheader);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
}
.subpage_backicon:hover {
    background-color: var(--cardheaderhover);
}
.subtitle_icon {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 10px #ccc;
    color: #022648;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
}
.subtitle {
    font-size: 17px;
    font-weight: 500;
}
/* More Buttons */
.btn_icon {
    width: 3vh;
    height: 3vh;
    padding: 0;
    border-radius: 5px !important;
}
.btn_preloader {
    width: 10vh;
    height: 2.5vh;
}
.buttontwo {
    display: inline-block;
    position: relative;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 5px;
    width: fit-content;
    min-width: 100px;
    height: 4vh;
    text-align: center;
    font-size: 15px;
    transition: background-color 500ms, transform 100ms;
}
.buttontwo_textWrapper {
    overflow: hidden;
}
.buttontwo_icon,
.buttontwo_text {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}
.buttontwo_icon,
.buttontwo_text {
    transition: top 500ms;
    color: white;
}
.buttontwo_text {
    line-height: 4vh;
    top: 0;
}
.buttontwo_icon {
    top: 100%;
    font-size: 20px;
    opacity: 0;
}
.buttontwo:hover .buttontwo_text {
    top: -100%;
    opacity: 0;
}
.buttontwo:hover .buttontwo_icon {
    top: 2px;
    font-size: 20px;
    opacity: 1;
}
@media screen and (max-width: 1025px) {
    .buttontwo:hover .buttontwo_icon {
        top: 4px;
    }
}
@media screen and (max-width: 800px) {
    .buttontwo:hover .buttontwo_icon {
        top: 0.5px;
    }
}
.filteroptionContainer {
    width: 4.5vh;
    height: 4.5vh;
    background: var(--cardheader);
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    cursor: pointer;
    transition: 0.3s;
}
.filteroptionContainer:hover {
    background: var(--cardheaderhover);
}

/* Pagination */
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
.pagination .page_link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #022648;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.pagination .page_link:hover {
    z-index: 2;
    color: #eac435;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.pagination .page_link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none;
}
.pagination .page_link:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.page_item:first-child .page_link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.page_item:last-child .page_link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.page_item.active .page_link {
    z-index: 1;
    color: #fff;
    background-color: #eac435;
}
.page_item.disabled .page_link {
    color: #eac435;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
    cursor: not-allowed;
}

/* Borders */
/* .border_primary {
    border-color: #022648;
}
.border_secondary {
    border-color: #eac435;
}
.border_success {
    border-color: #3ac47d;
}
.border_info {
    border-color: #16aaff;
}
.border_warning {
    border-color: #f7b924;
}
.border_danger {
    border-color: #d92550;
}
.border {
    border: 1px solid #dee2e6 !important;
}
.border_top {
    border-top: 1px solid #dee2e6 !important;
}
.border_right {
    border-right: 1px solid #dee2e6 !important;
}
.border_bottom {
    border-bottom: 1px solid #dee2e6 !important;
}
.border_left {
    border-left: 1px solid #dee2e6 !important;
}
.border_0 {
    border: 0 !important;
}
.border_top_0 {
    border-top: 0 !important;
}
.border_right_0 {
    border-right: 0 !important;
}
.border_bottom_0 {
    border-bottom: 0 !important;
}
.border_top_0 {
    border-top: 0 !important;
}
.border_left_0 {
    border-left: 0 !important;
} */
/* Inner Content */
.app_container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;
}
.app_main {
    position: relative;
    flex-direction: column;
    z-index: 9;
    padding-left: 210px;
    padding-right: 15px;
}
.app_main_translated {
    padding-right: 210px;
    padding-left: 15px;
}
.app_main .app_main_inner {
    flex: 1;
    
}

/* Page Title */
.app_page_title {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-weight: 400;
    margin-left: auto;
    color: #022648;
    font-weight: 500;
}
.app_page_title p {
    margin: 0;
}
.pagetitle {
    font-size: 21px;
    margin-bottom: 0;
}

/* Card */
.card {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 15px;
    padding: 0.5em 0.8em;
    /* box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03); */
    transition: all 0.2s;
    /* box-shadow: 0 0 10px #e6e6e6; */
}
.innercard{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 15px;
    padding: 0.5em 0.8em;
    transition: all 0.2s;
    /* box-shadow: 0 0 10px #e6e6e6; */
    /* min-height: 100vh; */
    margin-bottom: 5px;
    border: 2px solid #eee;
}
.filtercard{
    background-color: #fff;
    border-radius: 15px;
    padding: 0.5em 0.8em;
    transition: all 0.2s;
    /* box-shadow: 0 0 10px #e6e6e6; */
    /* height: auto !important; */
}
.hover_card {
    transition: 0.3s;
}
.hover_text,
.hover_text_container {
    transition: 0.3s;
}
.hover_text:hover,
.hover_text_container:hover .hover_text {
    color: #eac435;
}
.hover_card:hover {
    box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card_subtitle {
    font-size: 15px !important;
    color: var(--light) !important;
}
.card_text:last-child {
    margin-bottom: 0;
}
.card_header,
.cardTitle {
    text-transform: capitalize;
    /* color: #008eb3; */
    color: #000;
    font-weight: 600;
    font-size: 16px;
}
.cardTitle_dark {
    color: rgba(13, 27, 62, 0.7);
}
.card_header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    color: inherit;
    background-color: #fff;
    border-bottom: 1px solid rgba(26, 54, 126, 0.125);
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.625rem;
    height: 3.5rem;
}
.card_header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card_sub_title {
    font-size: 13px;
}
.title_icon_cotainer {
    width: 4vh;
    height: 4vh;
    background-color: rgba(13, 27, 62, 0.55);
    border-radius: 50%;
    color: white;
}
.card_footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid rgba(26, 54, 126, 0.125);
    display: flex;
    align-items: center;
}
.card_footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card_header.no_border {
    border: 0;
    padding: 0;
    height: auto;
}
.card_border {
    box-shadow: 0 0 0 transparent;
    border-width: 1px;
}

/* Responsive */
@media (max-width: 991.98px) {
    .app_main {
        display: block;
        padding-left: 0 !important;
    }
    .app_main_translated {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (max-width: 800px) {
    .app_main .app_main_inner {
        padding: 15px 15px 0;
    }
    body .card.mb-3 {
        margin-bottom: 15px !important;
    }
    body .card_header {
        height: auto;
        display: block;
        padding: 0.75rem 1.5rem;
        text-align: center;
    }
}
/* Search Bar */
.searchbar_container {
    padding: 0;
    margin: 0;
    border: 0;
    position: relative;
}
.searchbar_container .fa_search {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    display: flex;
    align-items: center;
    text-align: center;
}
.search_bar {
    border: 2px solid #e6e6e6;
    width: 100%;
    height: 100%;
    padding: 7px 30px 7px 35px;
    background: white;
    font-size: 14px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px #e6e6e6; */
    transition: all 0.4s ease;
}
.search_bar:focus {
    /* outline: none; */
    border: 1px solid #e6e6e6 !important;
    /* box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3); */
}

.icon_search {
    position: relative;
    top: 8px;
    width: 40%;
    height: 40%;
    opacity: 1;
    border-radius: 50%;
    border: 3px solid #022648;
    transition: opacity 0.25s ease, transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.icons_container {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    width: 35px;
    height: 35px;
    overflow: hidden;
}
.icon_close {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    cursor: pointer;
    transform: translateX(-200%);
    border-radius: 50%;
    transition: all 0.25s ease, transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.icon_close:hover i {
    color: #eac435;
}
.search_bar:focus + .icons_container .icon_close {
    opacity: 1;
    transform: translateX(0);
    color: var(--danger);
}
.searchbar_containerfilter .verical_divider {
    height: 25px;
    background-color: #ccc;
}

/* Pills */
.pill {
    min-width: 9vh;
    min-height: 4vh;
    color: white;
    padding: 0 1%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 10px;
}
.pill2 {
    min-width: 7vh;
    height: 3.5vh;
    color: white;
    /* padding: 0 1%; */
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 10px;
}
.section {
    opacity: 1;
    display: block;
    transition: opacity 0.5s ease-in-out;
    transition-delay: 0s;
}
.section.inactive {
    opacity: 0;
    display: none;
    transition: opacity 0.5s ease-in-out;
}
.fadeIn {
    /* opacity: 1; */
    display: block;
    visibility: visible;
    transition: 1s ease-in-out;
    transition-delay: 0s;
}
.fadeOut {
    /* opacity: 0; */
    display: none;
    visibility: hidden;
    transition: 1s ease-in-out;
}
.fadeInButton {
    display: flex;
    align-items: center;
    justify-content: center;
}
.fadeOutButton {
    display: none;
}
/* Not Creared */
.notcreated_text {
    font-size: 19px;
    /* color: #999999; */
    color: var(--light);

    text-transform: capitalize;
}
.notcreated_icon {
    font-size: 35px;
    color: var(--light);
}
/* Customers */
.customers_max_width_200 {
    max-width: 200px;
}
.appearancegeneral_card {
    min-height: 250px;
}

.avatar_edit input {
    display: none;
}
.avatar_upload .avatar_edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.avatar_upload .avatar_preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 10px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar_upload_logo .avatar_preview {
    border-radius: 10px;
}
.avatar_upload .avatar_preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.avatar_preview img {
    border-radius: 5px;
    max-width: 95%;
    max-height: 95%;
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--primaryColor);
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--secondaryColor);
}
.imgpreviewtxt,
.imgpreviewpostertxt {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    transition: 0.3s;
    font-size: 15px;
    color:var(--light)
}
.avatar_upload:hover .imgpreviewtxt {
    color: var(--secondary);
}
.usericonCont {
    width: 3vh;
    height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
/* dont delete */
.variantminorcomponentcontinvariantscomp .addprodoptionbtn {
    display: none;
}
.horizontalscrollcont {
    height: 70px;
    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}
.horizontalscrollcont .horizontalscrollcardcont {
    width: 100%;
    float: none;
    display: inline-block;
    zoom: 1;
}

.home_activity_card_container {
    height: 315px;
}
.home_activity_card_container .btn_circle {
    border-radius: 10px;
}
.home_activity_card_container2 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
}